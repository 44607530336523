import React from 'react'

export default function LeadHeader({ title }) {
  return (
    <div className="bg-gray-200 p-5 leading-none text-center border-b border-gray-300">
      <h3 className="text-2xl">
        {title}
      </h3>
    </div>
  )
}
