import React from 'react'
import Slider from 'app/theme/slider'
import styles from './WhyChooseUs.module.css'
import { ChevronRight, ChevronLeft } from 'react-feather'

export default function WhyChooseUs({
  heading,
  items = []
}) {
  return (
    <div className="bg-gray-100 pt-10 pb-12 md:pt-16 md:pb-16 lg:pt-24 lg:pb-24">
      <div className="container">
        <h2 className={styles.heading}>
          {heading}
        </h2>
        <Slider
          className={styles.slider}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={true}
          dots={true}
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
          responsive={[
            {
              breakpoint: 1200,
              settings: {
                arrows: false,
              },
            },
          ]}
        >
          {items.map((item, key) => (
            <div key={key}>
              <div className="grid lg:grid-cols-2 gap-8 lg:items-center">
                <div className="flex justify-end">
                  {item.imageUrl && (
                    <img
                      src={item.imageUrl}
                      title={item.imageTitle}
                      alt={item.imageTitle}
                      className={styles.image}
                    />
                  )}
                </div>
                <div>
                  <h3 className="font-bold text-2xl text-black mb-4 lg:text-3xl">
                    {item.heading}
                  </h3>
                  <p className="font-light text-lg lg:text-xl">
                    {item.description}
                  </p>
                  {item.buttonUrl || item.buttonAction ? (
                    <div className="mt-8">
                      {item.buttonUrl ? (
                        <a
                          href={item.buttonUrl}
                          className={['btn', styles.button]}
                        >
                          {item.buttonText}
                          <ChevronRight className="mt-1 ml-1" />
                        </a>
                      ) : (
                        <button
                          type="button"
                          className={['btn', styles.button]}
                          onClick={item.buttonAction}
                          children={item.buttonText}
                        >
                          {item.buttonText}
                          <ChevronRight className="mt-1 ml-1" />
                        </button>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

function PrevArrow(props) {
  return (
    <button className={props.className} onClick={props.onClick}>
      <ChevronLeft size={34} className="text-white" />
    </button>
  )
}

function NextArrow(props) {
  return (
    <button className={props.className} onClick={props.onClick}>
      <ChevronRight size={34} className="text-white" />
    </button>
  )
}
