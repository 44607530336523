import React, { useState } from 'react'
import Header from './LeadHeader'
import Footer from './LeadFooter'
import Input from 'app/theme/forms/Input'

const emailRegex = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/

export default function LeadEmail(props) {
  const { data, setData, nextStep } = props
  const [error, setError] = useState()

  const validate = () => {
    if (data.email && emailRegex.test(data.email)) {
      return true
    }
    return setError(
      'Please enter a valid email'
    )
  }

  return (
    <div>
      <Header
        title="Get your marketing campaign rolling"
      />
      <div className="p-8 text-center sm:w-2/3 m-auto">
        <Input
          value={data.email}
          error={error}
          placeholder="Email Address"
          classNames={{
            input: 'input-underline text-center',
          }}
          onChange={email => {
            setError(null)
            setData(prev => ({ ...prev, email }))
          }}
        />
      </div>
      <Footer
        nextStep={nextStep}
        validate={validate}
      />
    </div>
  )
}
