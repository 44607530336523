import React from 'react'

export default function CompanyLogos({
  heading,
  subheading,
  logos = [],
}) {
  return (
    <div className="bg-white pt-10 pb-12 lg:pt-16 lg:pb-24">
      <div className="container">
        <h2 className="text-3xl md:text-5xl font-light md:font-normal text-black font-platform text-left lg:text-center">
          {heading}
        </h2>
        <p className="text-black text-2xl text-left lg:text-center font-light py-2 lg:py-3">
          {subheading}
        </p>
        <div
          className={[
            'mt-8 flex flex-wrap gap-6 md:gap-12 items-center justify-center self-center',
          ]}
        >
          {logos.map((logo, index) => (
            <div
              key={index}
              className="flex-initial self-center"
            >
              <img
                src={logo.url}
                alt={logo.title}
                className="m-auto w-32"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
