import { useMemo } from 'react'
import { useQuery } from 'jsonapi-react'
import { addDays, format } from 'date-fns'

export default function useEventQuery(catererId, maxDays = 30, options, config) {
  const [minDate, maxDate] = useMemo(() => {
    const minDate = new Date()
    const maxDate = addDays(minDate, maxDays)
    return [minDate, maxDate]
  }, [maxDays])

  return useQuery(catererId && ['calendar_events', {
    expand_recurring: true,
    filter: {
      lapsed: false,
      caterer_id: catererId,
      min_date: format(minDate, 'yyyy-MM-dd'),
      max_date: `${format(maxDate, 'yyyy-MM-dd')}T23:59:59`,
    },
    include: [
      'event',
      'event.occasion',
      'caterer_service'
    ],
    page: {
      size: 5,
      number: 1,
    },
    fields: {
      caterer_services: [
        'type'
      ],
    },
    sort: [
      'start_date',
    ],
    stats: {
      total: 'count',
    },
    ...options,
  }], {
    ssr: false,
    ...config,
  })
}
