import React from 'react'
import { useQuery } from 'jsonapi-react'
import { differenceInCalendarYears, differenceInMonths } from 'date-fns'
import styles from './DetailsBar.module.css'
import RankingBadge from 'app/theme/RankingBadge'

import forkKnife from 'assets/svg/fork-knife.svg'
import cart from 'assets/svg/cart.svg'
import truck from 'assets/svg/truck.svg'
import trailer from 'assets/svg/trailer.svg'
import popup from 'assets/svg/popup-tent.svg'
import mapIcon from 'assets/svg/map-icon.svg'
import redHeart from 'assets/svg/red-heart.svg'
import dropOff from 'assets/svg/drop-off.svg'
import traditional from 'assets/svg/full-service.svg'

const serviceTypes = {
  truck: {
    name: 'Food Truck',
    icon: truck,
  },
  trailer: {
    name: 'Trailer',
    icon: trailer,
  },
  cart: {
    name: 'Cart',
    icon: cart,
  },
  popup: {
    name: 'Popup Catering',
    icon: popup,
  },
  traditional: {
    name: 'Full Service Catering',
    icon: traditional,
  },
  dropoff: {
    name: 'Drop Off Catering',
    icon: dropOff,
  }
}

function unique(value, index, self) {
  return self.indexOf(value) === index
}

function getServiceLabels(services) {
  return services.map(s => s.type)
    .filter(unique)
    .filter(type => serviceTypes[type])
    .map(type => serviceTypes[type].name)
}

function formatBusinessTime(date) {
  const now = new Date()
  const start = new Date(date)
  const years = differenceInCalendarYears(now, start)

  if (years > 1) {
    return `${years}+ Year${years > 1 ? 's' : ''}`
  }

  const months = differenceInMonths(now, start)
  if (months > 0) {
    return `${months} Month${months > 1 ? 's' : ''}`
  }
  
  return `New`;
}

export default function DetailsBar(props) {
  const { caterer, city, showName, showBox, showLeadForm } = props

  const maxMiles = 10

  const areaFilter = {}
  if ((city.type === 'city' && city.major) || !city.location) {
    areaFilter.city_id = city.id
  } else {
    areaFilter.service_area = {
      contains: JSON.stringify({ ...city.location, radius: maxMiles })
    }
  }

  const query = useQuery(['tags', {
    sort: 'name',
    filter: {
      ...areaFilter,
      id: caterer.tags.map(t => t.id)
    },
    fields: {
      tags: ['name', 'slug']
    },
    extra_fields: {
      tags: ['total_caterers'],
    },
    page: {
      size: 5,
    },
    stats: {
      total: 'count',
    }
  }])

  if (query.isLoading) {
    return null
  }

  const linkedCuisineIds = query.data.map(t => t.id)

  let types = caterer.services.map(s => s.type).sort((a,b) => Object.keys(serviceTypes).indexOf(a) - Object.keys(serviceTypes).indexOf(b))

  return (
    <div className="bg-gray-200 border border-gray-200 border-b-1">
      <div className="container py-10 lg:flex">
        <div className="flex-1">
          <div>
            {showName && (
              <h1 className="text-navy text-4xl lg:text-5xl font-platform leading-none mb-8">
                {caterer.name}
              </h1>
            )}
            <div className="flex flex-wrap text-center -mb-10 md:mb-0 md:flex-no-wrap">
              {caterer.ranking_tier && (
                <div className={styles.iconBox}>
                  <RankingBadge
                    ranking={caterer.ranking_tier}
                    alt={caterer.ranking_tier == 1 ? 'Top Caterer' : 'Preferred Caterer'}
                  />
                  <div>
                    {caterer.ranking_tier == 1 ? 'Top Caterer' : 'Preferred Caterer'}
                  </div>
                </div>
              )}
              <div className={styles.iconBox}>
                <img src={forkKnife} alt="Tags" />
                {caterer.tags.map((tag, index) => {
                  return (
                    <a
                      key={tag.id}
                      href={city ? `/food-trucks/${city.url_slug}/${tag.slug}/` : undefined}
                      className={city ? 'hover:underline' : null}
                      rel={linkedCuisineIds.includes(tag.id) ? undefined : 'nofollow'}
                    >
                      <span>
                        {tag.name}{', '}
                      </span>
                    </a>
                  )
                })}
                {city && (
                  <a
                    href={`/food-trucks/${city.url_slug}/`}
                    className={'hover:underline'}
                  >
                    <span>
                      {city.name} Food Trucks
                    </span>
                  </a>
                )}
              </div>
              {caterer.services.length > 0 && (
                <div className={styles.iconBox}>
                  <img
                    src={ serviceTypes[types[0]]?.icon || truck }
                    alt={ serviceTypes[types[0]]?.name || 'Food Truck' }
                  />
                  {getServiceLabels(caterer.services).join(', ')}
                </div>
              )}
              {city && (
                <div className={styles.iconBox}>
                  <img
                    src={mapIcon}
                    alt="Location"
                  />
                  {city.country.code === 'US' ? (
                    <span>{city.name}, {city.region?.code}</span>
                  ) : (
                    <span>{city.display_name}</span>
                  )}
                </div>
              )}
              <div className={styles.iconBox}>
                <img src={redHeart} />
                <div>
                  {caterer.story?.established_date ? (
                    <span>
                      {formatBusinessTime(caterer.story.established_date)}
                      <span> in business</span>
                    </span>
                  ) : (
                    <span>
                      {formatBusinessTime(caterer.created_at)}
                      <span> on Roaming Hunger</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showBox && (
          <div
            className={[
              'relative z-10 bg-white shadow p-6 mt-10',
              'lg:-mt-16 lg:w-1/3',
            ]}
          >
            <h2 className="text-blue mb-1 font-normal text-2xl xl:text-3xl">
              <a href="/food-truck-catering/" >
                Hire a Food Truck
              </a>
            </h2>
            <p className="text-md xl:text-lg mb-4 font-light text-gray-600">
              Food truck catering for your next event. Fast, easy and
              delicious. Book it here.
            </p>
            <button
              children="Get Started"
              className="btn btn-blue font-platform text-xl pt-1 lg:pt-2 px-4 pb-2 lg:pb-3"
              onClick={showLeadForm}
            />
          </div>
        )}
      </div>
    </div>
  )
}
