import React, { useState, useEffect } from 'react'
import { useClient } from 'jsonapi-react'
import useSessionData from 'app/utils/useSessionData'
import useSessionStorage from 'lib/useSessionStorage'
import { steps } from './form/config'
import useEventTracker from 'lib/useEventTracker'

export default function LeadForm(props) {
  const { closeModal, initialData = {} } = props

  const client = useClient()
  const [sessionData] = useSessionData()
  const [sessionStorage, setSessionStorage] = useSessionStorage('agency_lead')
  const tracker = useEventTracker()

  const [step, setStep] = useState(steps[0])

  const [data, setData] = useState(() => {
    const data = {
      ...initialData,
      ...sessionStorage,
      meta: sessionData.meta
    }
    if (data.event_date) {
      data.event_date = new Date(data.event_date)
    }
    return data
  })

  const prevStep = () => {
    const index = steps.findIndex(s => s === step)
    const prev = steps.slice(0, index).reverse().find(step =>
      step.isEnabled ? step.isEnabled(data) : true
    )
    if (prev) {
      setStep(prev)
    }
  }

  const nextStep = () => {
    const index = steps.findIndex(s => s === step)

    const next = steps.slice(index + 1).find(step =>
      step.isEnabled ? step.isEnabled(data) : true
    )

    if (step.label) {
      tracker.event('Next', {
        event_category: 'Agency Lead Form',
        event_label: step.label,
      })
    }

    if (next) {
      setStep(next)
    }
  }

  const save = (payload, id) => (
    client.mutate(['agency_leads', id], payload).then(res => {
      if (res.data) {
        setData(prev => ({ ...prev, id: res.data.id }))
      }
      return res
    })
  )

  useEffect(() => {
    if (step.autoSave) {
      save({ ...data, status: 'incomplete' }, data.id)
      setSessionStorage(data)
    }
  }, [step])

  const submit = () => {
    setStep(steps.find(s => s.name === 'loading'))

    tracker.event('Submit', {
      event_category: 'Agency Lead Form',
    })

    save({ ...data, status: 'new' }, data.id).then(res => {
      if (res.data) {
        setStep(steps.find(s => s.name === 'success'))
        setSessionStorage(undefined)

        tracker.event('Success', {
          event_category: 'Agency Lead Form',
        })
      } else {
        setStep(steps.find(s => s.name === 'fail'))

        tracker.event('Error', {
          event_category: 'Agency Lead Form',
        })
      }
    })
  }

  useEffect(() => {
    tracker.event('View', {
      event_category: 'Agency Lead Form',
    })
  }, [])

  return (
    <div className="bg-gray-100">
      <step.component
        submit={submit}
        prevStep={prevStep}
        nextStep={nextStep}
        data={data}
        setData={setData}
        closeModal={closeModal}
      />
    </div>
  )
}
