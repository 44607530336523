import React from 'react'
import Reviews from '../modules/Reviews'
import { useBlockContext } from '../BlockContext'

export default function ReviewsBlock({ data }) {
  const ctx = useBlockContext()

  return (
    <Reviews
      place={ctx.place}
      heading={data.heading}
      subheading={data.subheading}
    />
  )
}
