import React from 'react'
import styles from './LeadLoading.module.css'

export default function LeadLoading() {
  return (
    <div className="leading-none bg-blue text-center px-6 py-20">
      <div className={styles.loader}>
        <svg viewBox="25 25 50 50" >
          <circle cx="50" cy="50" r="20" fill="none" stroke="#fff" strokeWidth="2" />
        </svg>
      </div>
      <h2 className="mt-4 font-light text-4xl text-white text-center">
        Processing your request
      </h2>
      <p className="mt-6 font-light text-md text-white text-center">
        (Please be patient, this could take a few seconds)
      </p>
    </div>
  )
}
