import React from 'react'
import { ChevronRight } from 'react-feather'
import styles from './Hero.module.css'

export default function Hero({
  imageUrl,
  heading,
  subheading,
  description,
  buttonText,
  buttonAction,
  buttonUrl,
}) {
  return (
    <div className="bg-blue text-black">
      <div className={['grid grid-cols-1 lg:grid-cols-2', styles.container]}>
        <div
          className={[
            'bg-cover bg-center',
            styles.image,
          ]}
          style={{
            backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
          }}
        />
        <div className="flex flex-col justify-center max-w-2xl gap-2 container pt-8 pb-12 lg:py-20 lg:px-16 sm:m-0">
          <h1 className={styles.heading}>
            {heading}
            {subheading && (
              <span className={styles.subheading}>
                {subheading}
              </span>
            )}
          </h1>
          <p className={styles.description}>
            {description}
          </p>
          {buttonUrl || buttonAction ? (
            <div className="mt-2">
              {buttonUrl ? (
                <a
                  href={buttonUrl}
                  className={['btn', styles.button]}
                >
                  {buttonText}
                  <ChevronRight className="mt-1 ml-1" />
                </a>
              ) : (
                <button
                  type="button"
                  className={['btn', styles.button]}
                  onClick={buttonAction}
                  children={buttonText}
                >
                  {buttonText}
                  <ChevronRight className="mt-1 ml-1" />
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
