import React from 'react'
import { X } from 'react-feather'
import Slider from 'app/theme/slider'
import { Modal } from 'lib/modal'
import styles from './Gallery.module.css'
import { getResizedPhotoUrl } from 'app/utils/photos'

export default function Gallery({ photos, close }) {
  return (
    <Modal
      isOpen={true}
      closeModal={close}
      overlay={<div className="absolute inset-0 bg-navy" style={{ opacity: .9 }} />}
      className="h-screen w-full flex flex-col"

    >
      <div className="flex justify-end p-3">
        <button
          onClick={close}
          className=""
        >
          <X size={50} className="btn text-white opacity-75 hover:opacity-100" />
        </button>
      </div>
      <div className="flex-1 relative">
        <div className="absolute inset-0">
          <Slider
            className={styles.slider}
            arrows={true}
            dots={false}
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  dots: true,
                  arrows: false,
                },
              },
            ]}
          >
            {photos.map(photo => (
              <div key={photo.id} className="h-full">
                <div className="h-full flex items-center justify-center">
                  <img src={getResizedPhotoUrl(photo, 1200, null)} className="max-h-full mx-auto" />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="h-20">

      </div>
    </Modal>
  )
}
