import React from 'react'
import CompanyLogos from '../modules/CompanyLogos'

export default function CompanyLogosBlock({
  data
}) {
  return (
    <CompanyLogos
      heading={data.heading}
      subheading={data.subheading}
      logos={data.logos?.map(logo => ({
        url: logo.image_url,
        title: logo.image_title,
      })) || []}
    />
  )
}
