import React from 'react'
import { useBlockContext } from './BlockContext'

export function BlockRenderer({ blocks = [] }) {
  const config = useBlockContext().config || {}

  return (
    blocks.map((block, index) => {
      const Block = config[block.type]

      return Block ? (
        <Block key={index} {...block} />
      ) : null
    })
  )
}
