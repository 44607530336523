import React from 'react'
import Testimonials from '../modules/Testimonials'
import { useBlockContext } from '../BlockContext'
import { getButtonProps } from '../utils'

export default function TestimonialsBlock({ data }) {
  const ctx = useBlockContext()

  return (
    <Testimonials
      heading={data.heading}
      items={(data.items || []).map(item => ({
        testimonial: item.testimonial,
        imageUrl: item.image_url,
        imageTitle: item.image_title,
        eaterCount: item.eater_count,
        truckCount: item.truck_count,
        personName: item.person_name,
        personTitle: item.person_title,
      }))}
      {...getButtonProps(data, ctx)}
    />
  )
}
