import React from 'react'
import { Modal } from 'lib/modal'
import LeadForm from './LeadForm'
import { X } from 'react-feather'

export default function LeadModal(props) {
  const {
    isOpen,
    closeModal,
    ...rest
  } = props

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      className="relative m-5 w-full shadow-lg self-start"
      style={{ maxWidth: 600 }}
    >
      <LeadForm
        closeModal={closeModal}
        {...rest}
      />
      <button
        children={<X className="w-4 text-gray-900" />}
        className="btn absolute top-0 z-50 right-0 px-2 py-1 opacity-50 hover:opacity-100"
        onClick={closeModal}
      />
    </Modal>
  )
}
