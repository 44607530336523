import React from 'react'
import Calculator from '../modules/Calculator'
import { useBlockContext } from '../BlockContext'
import { getButtonProps } from '../utils'

export default function CalculatorBlock({ data }) {
  const ctx = useBlockContext()

  return (
    <Calculator
      heading={data.heading}
      subheading={data.subheading}
      defaultCity={ctx.place}
      {...getButtonProps(data, ctx)}
    />
  )
}
