import Calculator from './blocks/Calculator'
import CatererCarousel from './blocks/CatererCarousel'
import CateringForAnyEvent from './blocks/CateringForAnyEvent'
import CompanyLogos from './blocks/CompanyLogos'
import Hero from './blocks/Hero'
import Experiment from './blocks/Experiment'
import HowMuchDoesItCost from './blocks/HowMuchDoesItCost'
import HowToBook from './blocks/HowToBook'
import Reviews from './blocks/Reviews'
import Testimonials from './blocks/Testimonials'
import WhyChooseUs from './blocks/WhyChooseUs'

export default {
  Calculator,
  CatererCarousel,
  CateringForAnyEvent,
  CompanyLogos,
  Hero,
  Experiment,
  HowMuchDoesItCost,
  HowToBook,
  Reviews,
  Testimonials,
  WhyChooseUs,
}
