import React from 'react'
import Hero from '../modules/Hero'
import { useBlockContext } from '../BlockContext'
import { getButtonProps } from '../utils'

export default function HeroBlock({ data }) {
  const ctx = useBlockContext()

  return (
    <Hero
      imageUrl={data.image_url}
      heading={data.heading}
      subheading={data.subheading}
      description={data.description}
      {...getButtonProps(data, ctx)}
    />
  )
}
