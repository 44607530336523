import React from 'react'
import HowToBook from '../modules/HowToBook'
import { useBlockContext } from '../BlockContext'
import { getButtonProps } from '../utils'

export default function HowToBookBlock({ data }) {
  const ctx = useBlockContext()

  return (
    <HowToBook
      heading={data.heading}
      imageUrl={data.image_url}
      items={data.items || []}
      {...getButtonProps(data, ctx)}
    />
  )
}
