import React, { useState, useEffect } from 'react'
import styles from './Events.module.css'
import { useQuery } from 'jsonapi-react'
import { format, isToday, isTomorrow } from 'date-fns'
import EventMap from './EventMap'
import cart from 'assets/svg/cart.svg'
import truck from 'assets/svg/truck.svg'
import trailer from 'assets/svg/trailer.svg'
import popup from 'assets/svg/popup-tent.svg'
import dropoff from 'assets/svg/drop-off.svg'
import traditional from 'assets/svg/full-service.svg'
import useEventQuery from './useEventQuery'
import { getEventTitle, getEventUrl } from 'app/utils/events'

const serviceImage = (event) => {
  switch (event.caterer_service?.type) {
    case 'truck': return <img width="50" src={truck} />
    case 'trailer': return <img width="50" src={trailer} />
    case 'cart': return <img width="50"src={cart} />
    case 'popup': return <img width="50" src={popup} />
    case 'traditional': return <img width="50" src={traditional} />
    case 'dropoff': return <img width="50" src={dropoff} />
    default: return
  }
}

export default function Events({ caterer }){
  const [expanded, setExpanded] = useState(null)

  const [events, setEvents] = useState()

  const [options, setOptions] = useState({
    page: { size: 5, number: 1 },
  })

  const query = useEventQuery(caterer.id, 180, options)

  useEffect(() =>{
    if (query.data) {
      setEvents(prev => (prev || []).concat(query.data))
    }
  }, [query.data])

  if (!events) {
    return null
  }

  return (
    <div className="container pt-12 pb-20">
      <h2 className="text-navy font-platform text-4xl mb-3">
        Upcoming Locations
      </h2>
      {events.length ? (
        <div className="grid gap-5">
          {events.map(event => (
            <div
              key={event.id}
              className={[
                styles.event,
                expanded === event.id && styles.expanded,
              ]}
            >
              <div
                className={styles.date}
                onClick={(e) => {
                  setExpanded(expanded === event.id ? null : event.id)
                }}
              >
                <div>{format(event.start_date, 'MMM')}</div>
                <div>{format(event.start_date, 'd')}</div>
              </div>
              <div className="flex-1 leading-tight">
                <div
                  className="lg:flex p-3 md:p-4 lg:p-5"
                  onClick={(e) => {
                    if (e.target.localName === 'a' || !event.address) {
                      // don't expand the map on links
                      return
                    }
                    setExpanded(expanded === event.id ? null : event.id)
                  }}
                >
                  <div className="flex-1 lg:order-first">
                    {event.event && event.event.published ? (
                      <a href={getEventUrl(event.event)} className="text-gray-800 text-xl capitalize text-blue">
                        {getEventTitle(event)}
                      </a>
                    ) : (
                      <div className="text-gray-800 text-xl capitalize">
                        {getEventTitle(event)}
                      </div>
                    )}
                    <div className="font-light lg:text-lg">
                      {format(event.start_date, 'h:mm a')}
                      <span> - </span>
                      {format(event.end_date, 'h:mm a')}
                      {event.location && (
                        <>
                          <span> — </span>
                          <span className="italic">
                            {event.location}
                          </span>
                        </>
                      )}
                    </div>
                    {event.special_offers && (
                      <div className="font-light italic text-sm mt-1">
                        {event.special_offers}
                      </div>
                    )}
                  </div>

                  <div className="flex pt-4 lg:pt-0">
                    <div className="flex">
                      {serviceImage(event)}
                    </div>
                  </div>
                </div>
                {expanded === event.id && (
                  <div className={[styles.map]}>
                    <EventMap event={event} />
                  </div>
                )}
              </div>
            </div>
          ))}
          {query.meta?.stats && query.meta.stats.total.count > events.length && (
            <div className="flex justify-center mt-6">
              <button
                type="button"
                className={[
                  'btn btn-blue py-2 px-16 text-lg',
                  query.isLoading && 'opacity-50'
                ]}
                children="Show More"
                disabled={query.isLoading}
                onClick={() => {
                  setOptions(prev => ({ ...prev, page: { ...prev.page, number: prev.page.number + 1 } }))
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <p className="text-xl">
          No upcoming events currently listed.
        </p>
      )}
    </div>
  )
}
