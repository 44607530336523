import React from 'react'
import foods from 'assets/img/lead-form-finish.jpg'

export default function LeadSuccess({ closeModal }) {
  return (
    <div style={{ backgroundColor: '#00aeef' }}>
      <img src={foods} />
      <div className="text-center p-12 pt-0">
        <h2 className="font-platform text-4xl text-white text-center">
          Thanks!
        </h2>
        <p className="font-light text-md text-white">
          Our Agency team will review and get back to you within one business day.
        </p>
        <button onClick={closeModal} className="mt-8 bg-white text-blue text-xl px-10 py-1 font-platform font-light">
          Close
        </button>
      </div>
    </div>
  )
}
