import LeadEmail from './LeadEmail'
import LeadEvent from './LeadEvent'
import LeadContact from './LeadContact'
import LeadLoading from './LeadLoading'
import LeadSuccess from './LeadSuccess'
import LeadFail from './LeadFail'

export const steps = [
  {
    name: 'email',
    component: LeadEmail,
    label: 'Email',
  },
  {
    name: 'event',
    component: LeadEvent,
    autoSave: true,
    label: 'Event Details',
  },
  {
    name: 'contact',
    component: LeadContact,
    autoSave: true,
    label: 'Contact',
  },
  {
    name: 'loading',
    component: LeadLoading,
  },
  {
    name: 'success',
    component: LeadSuccess,
  },
  {
    name: 'fail',
    component: LeadFail,
  },
]
