export function getButtonProps(data, ctx) {
  return {
    buttonText: data.button_text,
    buttonUrl: data.button_url,
    buttonAction: data.button_type === 'catering_lead'
      ? ctx.openLeadModal
      : data.button_type === 'agency_lead'
        ? ctx.openAgencyModal
        : undefined
  }
}
