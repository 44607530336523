import React from 'react'
import { ReactComponent as Arrow } from 'assets/svg/arrow-right.svg'

export default function LeadFooter(props) {
  const { prevStep, nextStep, submit, validate } = props

  const tryNextStep = () => {
    if (validate && !validate()) {
      return
    } else if (nextStep) {
      nextStep()
    } else if (submit) {
      submit()
    }
  }

  return (
    <div
      className={[
        'border-t border-gray-300 p-8',
        'grid gap-3 md:grid-cols-2 md:gap-8',
      ]}
    >
      {prevStep && (
        <Button onClick={prevStep} className="btn-gray order-last md:order-first">
          <Arrow className="absolute left-0 w-4 h-4 ml-3 transform rotate-180" />
          <span>Back</span>
        </Button>
      )}
      <Button onClick={tryNextStep} className={['btn-blue', !prevStep && 'md:col-span-2']}>
        <span>{submit ? 'Finish' : 'Next'}</span>
        <Arrow className="absolute right-0 w-4 h-4 mr-3" />
      </Button>
    </div>
  )
}

function Button(props) {
  return (
    <button
      {...props}
      className={[
        props.className,
        'btn relative py-2 text-lg flex justify-center items-center',
      ]}
    />
  )
}
