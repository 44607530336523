import React, { useState } from 'react'
import Slider from 'app/theme/slider'
import forkKnife from 'assets/svg/fork-knife.svg'
import quotes from 'assets/svg/quotes-white.svg'
import styles from './About.module.css'
import stripHtml from 'app/utils/stripHtml'
import { getResizedPhotoUrl } from 'app/utils/photos'

const questions = [
  { field: 'business_name', color: 'pink', question: 'What is the story behind your business\' name?' },
  { field: 'education', color: 'green', question: 'Where did you learn to cook?' },
  { field: 'reason_started', color: 'blue', question: 'Why did you decide to start your business?' },
  { field: 'encouraged_by', color: 'red', question: 'Who encouraged you the most to start your own business?' },
  { field: 'favorite_event', color: 'gray', question: 'What\'s your favorite event that you\'ve catered for?' },
  { field: 'favorite_dish', color: 'navy', question: 'What is your favorite dish on the menu?' },
]

export default function About({ caterer, story }) {
  const answers = story ? questions.filter(q => story[q.field]).map(q => ({
    ...q, answer: story[q.field]
  })) : null

  const [color, setColor] = useState(answers && answers[0]?.color)

  return (
    <div className="bg-white">
      {story && story.status !== 'disapproved' && answers.length > 0 ? (
        <div className="lg:flex">
          <div
            className={[
              'lg:order-2 lg:w-1/2',
              styles.storyBox,
              styles[color],
            ]}
          >
            {story.photos?.length > 0 && (
              <div className={styles.storyPhoto}>
                <div className={styles.spacer} />
                <img
                  src={getResizedPhotoUrl(story.photos[0], 1024, 1024)}
                />
              </div>
            )}
            <div className="p-12 xl:p-16 text-white">
              {story.owner_name && (
                <div className="font-platform text-5xl mb-5 leading-none">
                  Meet {story.owner_name}
                </div>
              )}
              <Slider
                className={styles.slider}
                arrows={false}
                dots={true}
                afterChange={index => {
                  if (answers[index]) {
                    setColor(answers[index].color)
                  }
                }}
              >
                {answers.map(a => (
                  <div key={a.field}>
                    <h5 className="uppercase text-xl leading-tight font-bold mb-6">
                      {a.question}
                    </h5>
                    <img src={quotes} className="w-16 mb-2"/>
                    <p className="font-platform text-3xl leading-tight">
                      {a.answer}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="lg:flex-1 p-10 lg:py-20 px-12">
            <AboutText
              name={caterer.name}
              description={caterer.description}
              story={story}
            />
          </div>
        </div>
      ) : (
        <div className="container py-16">
          <AboutText
            name={caterer.name}
            description={caterer.description}
            story={story}
          />
        </div>
      )}
    </div>
  )
}

function AboutText({ story, name, description }) {
  return (
    <div className="overflow-hidden">
      <h2 className="text-navy my-5 text-4xl font-platform tracking-wide leading-none">
        About {name}
      </h2>
      {story?.story_tags?.length && story?.status !== 'disapproved' ? (
        <ul className="pb-6 grid grid-cols-2 gap-3 text-center lg:flex flex-wrap">
          {story.story_tags.map(tag => {
            return (
              <li
                key={tag.id}
                className={[
                  "flex items-center bg-white shadow px-4 py-2 mr-2",
                  "text-navy lg:text-lg uppercase leading-none tracking-wide"
                ]}
                children={tag.name}
              />
            )
          })}
        </ul>
      ): null}
      <div className="leading-normal text-2xl">
        <img
          src={forkKnife}
          style={{
            maxWidth: 70,
          }}
          className={[
            'float-left align-middle mt-3 mr-6 mb-1',
          ]}
        />
        <div
          className={[styles.dangerous_html, 'whitespace-normal text-lg']}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  )
}
