import React from 'react'
import { parseISO, format } from 'date-fns'
import Slider from 'app/theme/slider'
import truck from 'assets/svg/truck.svg'
import popup from 'assets/svg/popup-tent.svg'
import dropoff from 'assets/svg/drop-off.svg'
import trailer from 'assets/svg/trailer.svg'
import cart from 'assets/svg/cart.svg'
import traditional from 'assets/svg/full-service.svg'
import styles from './RecentBookings.module.css'

const serviceConfig = {
  truck: { label: 'Truck', icon: truck },
  popup: { label: 'Popup', icon: popup },
  cart: { label: 'Cart', icon: cart },
  trailer: { label: 'Trailer', icon: trailer },
  dropoff: { label: 'Dropoff', icon: dropoff },
  traditional: { label: 'Traditional', icon: traditional }
}

export default function RecentBookings({caterer}) {
  return (
    <div className="">
      <div className="container my-10 overflow-hidden md:px-10">
        <h3 className="text-3xl text-navy font-bold">Past Catering Events</h3>
        <p className="mb-4">{caterer.caterer_past_events.length} event organizers have booked {caterer.name} using Roaming Hunger</p>
        <Slider
          arrows={true}
          dots={false}
          rows={1}
          variableWidth={true}
          slidesToShow={3}
          slidesToScroll={3}
          className={styles.recent_bookings_slider}
          responsive={[{
            breakpoint: 768,
            settings: {
              dots: true,
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }]}
        >
          {caterer.caterer_past_events?.slice(0,10).map(booking => {
            const start_date = format(parseISO(booking.start_date), "MMMM yyyy")
            const eaters = booking.eaters
            const occasion = booking.occasion_name || "Other"
            const service_type = booking.service_type || 'truck'
            const location = `${booking.city_name}, ${booking.region_code}`
            return (
              <div key={booking.id}>
                <div 
                  className="flex bg-white border m-2 p-2 rounded shadow" 
                >
                  <img 
                    className="pr-4" 
                    style={{
                      width: "80px",
                      maxHeight: "50px",
                    }}
                    src={serviceConfig[service_type].icon}
                  />
                  <div className="flex-grow justify-center">
                    <p className="text-sm font-bold">{occasion} Catering</p>
                    <p className="text-sm">{start_date} * {location}</p>
                    <p className="text-sm">{eaters}+ attendees</p>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}