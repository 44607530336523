import React from 'react'
import GoogleMap from 'app/maps/GoogleMap'
import { Marker } from '@react-google-maps/api'

export default function EventMap({ event }) {
  return (
    <GoogleMap
      className="w-full"
      zoom={14}
      width={870}
      useMarker={true}
      center={{
        lng: event.address.location?.lng,
        lat: event.address.location?.lat
      }}
    >
      <Marker
        position={{
          lng: event.address.location?.lng,
          lat: event.address.location?.lat
        }}
      />
    </GoogleMap>
  )
}
