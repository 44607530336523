import React, { useState } from 'react'
import Header from './LeadHeader'
import Footer from './LeadFooter'
import Input from 'app/theme/forms/Input'
import DatePicker from 'app/theme/date-picker/DatePicker'
import Textarea from 'app/theme/forms/Textarea'

export default function LeadEvent(props) {
  const { data, setData, prevStep, nextStep } = props

  const [disableBefore] = useState(() => {
    return new Date()
  })

  const [errors, setErrors] = useState({})

  const validate = () => {
    const errs = {}

    if (!data.event_date) {
      errs.event_date = 'Please enter an event date (can be approximate)'
    }

    if (!data.market) {
      errs.market = 'Please enter one or more target markets'
    }

    if (!data.company) {
      errs.company = 'Please provide the company or organization name'
    }

    if (!data.message) {
      errs.message = 'Please give us a little bit of info about your project or campaign'
    }

    if (Object.keys(errs).length) {
      return setErrors(errs)
    } else {
      return true
    }
  }

  return (
    <div>
      <Header
        title="Tell us when, where, and what we'll accomplish"
      />
      <div className="p-8 grid gap-8 sm:w-3/4 m-auto">
        <DatePicker
          value={data.event_date}
          error={errors.event_date}
          disableBefore={disableBefore}
          placeholder="Event Date"
          classNames={{
            input: 'input-underline',
          }}
          onChange={event_date => {
            setErrors(prev => ({
              ...prev,
              event_date: null,
            }))
            setData(prev => ({
              ...prev,
              event_date
            }))
          }}
        />
        <Input
          value={data.market}
          error={errors.market}
          placeholder="Market/Cities"
          classNames={{
            input: 'input-underline',
          }}
          onChange={market => {
            setErrors(prev => ({
              ...prev,
              market: null,
            }))
            setData(prev => ({
              ...prev, market
            }))
          }}
        />
        <Input
          value={data.company}
          error={errors.company}
          placeholder="Company or Client Name"
          classNames={{
            input: 'input-underline',
          }}
          onChange={company => {
            setErrors(prev => ({
              ...prev,
              company: null,
            }))
            setData(prev => ({
              ...prev,
              company
            }))
          }}
        />
        <Input
          value={data.budget_range}
          error={errors.budget_range}
          placeholder="Budget Range"
          classNames={{
            input: 'input-underline',
          }}
          onChange={budget_range => {
            setErrors(prev => ({
              ...prev,
              budget_range: null,
            }))
            setData(prev => ({
              ...prev,
              budget_range
            }))
          }}
        />
        <Textarea
          value={data.message}
          error={errors.message}
          placeholder="What's the purpose of the promotion?"
          rows="7"
          classNames={{
            input: 'input-underline',
          }}
          onChange={message => {
            setData(prev => ({ ...prev, message }))
          }}
        />
      </div>
      <Footer
        validate={validate}
        prevStep={prevStep}
        nextStep={nextStep}
      />
    </div>
  )
}
