import React, { useMemo } from 'react'
import LeadModal from 'app/leads/LeadModal'
import { useModalState } from 'lib/modal'
import useAgencyLeadModal from 'app/agency/useLeadModal'
import { BlockContext } from './BlockContext'
import { BlockRenderer } from './BlockRenderer'
import config from './block.config'

export function Blocks({ blocks = [], context }) {
  const { openModal, ...modalState } = useModalState()
  const { Modal: AgencyModal, openModal: openAgencyModal } = useAgencyLeadModal()

  const ctx = useMemo(() => ({
    ...context,
    config: config,
    openLeadModal: openModal,
    openAgencyModal: openAgencyModal,
  }), [context])

  return (
    <>
      <LeadModal {...modalState} />
      <AgencyModal />
      <BlockContext.Provider value={ctx}>
        <BlockRenderer blocks={blocks} />
      </BlockContext.Provider>
    </>
  )
}
