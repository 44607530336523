import React, { useEffect, useRef } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery } from 'jsonapi-react'
import { Helmet } from 'react-helmet-async'
import { Layout } from 'app/theme/layout'
import NotFoundPage from 'app/pages/NotFoundPage'
import ErrorPage from 'app/pages/ErrorPage'
import ResourceNotFoundPage from 'app/pages/ResourceNotFoundPage'
import Hero from './profile/Hero'
import DetailsBar from './profile/DetailsBar'
import GoodFors from './profile/GoodFors'
import Quote from './profile/Quote'
import Menu from './profile/Menu'
import CateringPackages from './profile/CateringPackages'
import About from './profile/About'
import BookNextEvent from 'app/theme/banners/BookNextEvent'
import Events from './profile/Events'
import LeadModal from 'app/leads/LeadModal'
import NavBar from 'app/theme/NavBar'
import Reviews from './profile/Reviews'
import RecentBookings from './profile/RecentBookings'
// import FAQ from './profile/FAQ'
import { useModalState } from 'lib/modal'
import { useInView } from 'react-intersection-observer'
import { useFeatureFlags } from 'app/utils/feature-flags'
import { getServiceLabels } from 'app/utils/catererServices'
import CustomPage from 'app/pages/CustomPage'
import posthog from 'posthog-js'

export default function ProfilePage({ url, staticContext }) {
  const { catererSlug } = useParams()
  const [flags] = useFeatureFlags()

  if (staticContext) {
    staticContext.cacheMaxAge = 10800
  }

  const query = useQuery(['caterers', catererSlug, {
    filter: {
      packages: {
        instant_book: "true"
      }
    },
    include: [
      'story',
      'story.photos',
      'story.story_tags',
      'packages',
      'packages.photo',
      'packages.categories',
      'packages.categories.items',
      'photos',
      'services',
      'tags',
      'good_fors',
      'menu_categories',
      'menu_categories.items',
      'menu_categories.items.photo',
      'cities',
      'cities.region',
      'cities.country',
      'caterer_stat',
      'caterer_past_events',
    ],
    fields: {
      caterers: ['name', 'slug', 'level', 'closed', 'description', 'short_description', 'website', 'twitter', 'facebook', 'instagram', 'yelp', 'ranking_tier', 'verified', 'closed', 'created_at'],
      cities: ['name', 'url_slug', 'display_name'],
      regions: ['name', 'slug', 'code'],
      countries: ['name', 'slug', 'code'],
      photos: ['path', 'type', 'url', 'uuid', 'extension'],
      story_tags: ['name'],
      tags: ['name', 'slug'],
      good_fors: ['name'],
      package_categories: ['name'],
      package_items: ['name', 'description', 'modifiers'],
      stories: ['quote', 'owner_name', 'established_date', 'status', 'created_at'],
      caterer_stags: ['total_reviews', 'average_rating'],
      caterer_services: ['type'],
      menu_categories: ['name', 'description'],
      menu_items: ['name', 'description', 'modifiers'],
    }
  }])

  const reviewsQuery = useQuery(query.data?.id && ['reviews', {
    filter: { caterer_id: query.data.id },
    sort: '-rating',
    include: [
      'review_tags'
    ],
    fields: {
      reviews: ['name', 'comment', 'rating']
    },
    page: {
      limit: 20,
    },
    stats: {
      total: 'count',
    }
  }])

  useEffect(() => {
    if (!query.isLoading && query.data) {
      posthog.capture('$pageview', {
        vendor_id: query.data.id,
        vendor_name: query.data.name
      }, )
    }
  }, [query.isLoading])

  const { openModal, ...modalState } = useModalState()

  const [heroRef, heroInView] = useInView({
    rootMargin: '1px',
  })

  const [eventsRef, eventsInView] = useInView({
    rootMargin: '100px',
    triggerOnce: true,
  })

  const navbarRef = useRef()

  if (query.isLoading) {
    return null
  }

  if (query.error && query.error.code !== 'not_found') {
    return <ErrorPage />
  }

  if (!query.data) {
    return <CustomPage />
  }

  if (query.data.slug !== catererSlug) {
    return <Redirect to={`/${query.data.slug}/`} />
  }

  const caterer = query.data
  const city = caterer.cities[0]

  if (caterer.closed) {
    return <ResourceNotFoundPage />
  }

  const preferred = (
    caterer.verified || (caterer.ranking_tier && caterer.ranking_tier <= 2)
  )

  const categories = caterer.menu_categories.filter(cat => cat.items.length > 0)

  const defaultMenuPhoto = caterer.photos.find(p => p.type === 'package_default')

  const metaTitle = [
    caterer.name,
    caterer.cities.length ? caterer.cities[0].name : null,
    'Roaming Hunger'
  ].filter(Boolean).join(' - ')

  let metaDescription = (
    (caterer.short_description || '').trim() ||
    (caterer.description || '').trim() || ''
  ).substr(0, 197).replace(/\n/, ' ')

  if (metaDescription.length === 197) {
    metaDescription += '...'
  }

  let restaurantMetaTags = {
    "@context": "https:\/\/schema.org",
    "@type": "Restaurant",
    "description": (caterer.description || '').trim() || metaDescription,
    "image": {
      "@type": "ImageObject",
        "width": "720",
        "height": "480",
        "name": `${caterer.name} Object 1`,
        "url": caterer.photos[0]?.url,
        "@id": caterer.photos[0]?.url
    },
    "name": caterer.name,
    "url": `https://roaminghunger.com/${caterer.slug}/`,
    "servesCuisine": caterer.tags.map(tag => tag.name).join(","),
    "currenciesAccepted": "USD",
    "knowsLanguage": "en-US",
    "hasDriveThroughService": false,
    "acceptsReservations": false,
    "areaServed": {
      "@type": "City",
      "name": city.name,
    },
    "sameAs": [
      caterer.website,
      caterer.twitter,
      caterer.facebook,
      caterer.instagram,
      caterer.yelp
    ].filter(x => x).join(', '),
    "hasMenu": {
      "@type": "Menu",
      "name": caterer.name,
      "description": "Food Truck menu for catering/vending service",
      "hasMenuSection": categories?.map(category => ({
        "@type": "MenuSection",
        "name": category.name,
        "hasMenuItem": category.items.map(item => ({
          "@type": "MenuItem",
          "name": item.name,
          "description": item.description,
          "image": item.photo?.url,
        }))
      }))
    },
    "url": caterer.website,
    "subjectOf": {
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": `Does ${caterer.name} do catering, and are they available on my date?`,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": `Yes, ${caterer.name} is registered for catering and/or vending events on Roaming Hunger. In terms of availability, we don't manage their calendar. The best way to find out if they're available is to fill out your event information and see if they respond.`
          }
        }, {
          "@type": "Question",
          "name": `Is ${caterer.name} available in my city?`,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": `${caterer.name} has a home base in ${caterer.cities.map(city => city.name).join(" and ")} and surrounding areas. Keep in mind that for catering, they may add a travel fee if it's outside of their normal travel radius.`
          }
        }, {
          "@type": "Question",
          "name": `What type of food does ${caterer.name} serve?`,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": `${caterer.name} serves the following cuisine types: ${caterer.tags?.map(tag => tag.name).join(", ")}`
          }
        }, {
          "@type": "Question",
          "name": `What types of services (equipment) does ${caterer.name} have?`,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": `${caterer.name} has the following types of services: ${getServiceLabels(caterer.services).join(", ")}`
          }
        }
      ],
      "name": caterer.name,
      "@id": `https://roaminghunger.com/${caterer.slug}/#FAQ`
    },
  }


if (reviewsQuery.data?.length) {
  restaurantMetaTags.review = reviewsQuery.data?.map(review => ({
    "@type": "Review",
    "author": review.name,
    "reviewBody": review.comment,
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": review.rating
    }
  }))
}

if (caterer.stats?.total_reviews && caterer.caterer_stats?.average_rating) {
  restaurantMetaTags.aggregateRating = {
    "@type": "AggregateRating",
    "ratingCount": caterer.caterer_stats?.total_reviews,
    "ratingValue": caterer.caterer_stats?.average_rating,
    "bestRating": undefined,
  }
}

if (caterer.caterer_stats?.average_rating) {
  restaurantMetaTags.starRating = {
    "@type": "Rating",
    "ratingValue": caterer.stats?.average_rating
  }
}

restaurantMetaTags = JSON.stringify(restaurantMetaTags)

return (
  <Layout>
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription || caterer.name} />
      <meta name="og:title" content={metaTitle}></meta>
      <meta name="og:description" content={metaDescription}></meta>
      <meta name="og:type" content="website"></meta>
      <script type="application/ld+json">
        {restaurantMetaTags}
      </script>
    </Helmet>
    <div ref={heroRef}>
      <Hero
        caterer={caterer}
        photos={caterer.photos.filter(p => p.type !== 'menu_item')}
        preferred={preferred}
        showLeadForm={openModal}
      />
    </div>
    <div ref={navbarRef} className="hidden lg:block fixed top-0 left-0 w-full z-50">
      {!heroInView && navbarRef.current && (
        <NavBar>
          <div className="flex justify-end">
            <button
              children="Book Catering"
              onClick={openModal}
              className={[
                'btn text-white font-platform text-lg px-8 py-2',
                'border-2 border-white'
              ]}
            />
          </div>
        </NavBar>
      )}
    </div>
    <DetailsBar
      caterer={caterer}
      city={city}
      showName={!preferred}
      showBox={!preferred}
      showLeadForm={openModal}
    />
    {caterer.good_fors.length > 0 && (
      <GoodFors
        goodFors={caterer.good_fors}
      />
    )}
    {caterer.story?.quote && caterer.story.status !== 'disapproved' && (
      <Quote
        caterer={caterer}
        quote={caterer.story.quote}
        author={caterer.story.owner_name}
      />
    )}
    {categories.length > 0 && (
      <Menu
        catererName={caterer.name}
        categories={categories}
        itemPhotos={caterer.photos.filter(p => p.type === 'menu_item')}
        defaultPhoto={defaultMenuPhoto}
        hasQuote={!!caterer.story?.quote}
        className={caterer.good_fors.length || (caterer.story?.quote && caterer.story.status !== 'disapproved') ? null : 'pt-12'}
      />
    )}

    <CateringPackages
      caterer={caterer}
      defaultPhoto={defaultMenuPhoto} />

    {caterer.caterer_past_events?.length ? (
      <RecentBookings caterer={caterer} />
    ) : null}

    {/* Disabled while waiting for new designs */}
    {/* <Reviews reviewsQuery={reviewsQuery} caterer={caterer} /> */}

    {(caterer.description || (caterer.story && caterer.story.status !== 'disapproved')) && (
      <About
        caterer={caterer}
        story={caterer.story}
      />
    )}

    {/* <FAQ caterer={caterer} /> */}

    <div ref={eventsRef} />
    {eventsInView && (
      <Events
        caterer={caterer}
      />
    )}
    <BookNextEvent
      onButtonClick={openModal}
    />
    <LeadModal
      initialData={{
        meta: {
          page_type: 'profile',
          caterer_id: caterer.id,
        }
      }}
      catererName={caterer.name}
      {...modalState}
    />
  </Layout>
)
}
