import React, { useState } from 'react'
import Slider from 'app/theme/slider'
import Tooltip from 'app/theme/Tooltip'
import styles from './CateringPackages.module.css'
import forkKnife from 'assets/svg/fork-knife.svg'
import { Modal } from 'lib/modal'
import { X } from 'react-feather'
import { getResizedPhotoUrl } from 'app/utils/photos'

export default function CateringPackages(props) {
  const { caterer, defaultPhoto } = props
  const [catererPackage, setCatererPackage] = useState()

  return (
    <div className="container">
      {caterer?.packages?.length ? (
        <div className="my-10">
          <h3 className="text-3xl text-navy font-bold">Catering Packages</h3>
          <p>
            {caterer.name} has {caterer.packages.length} public catering package(s) and can also offer custom catering packages. Pricing will depend on the event date, number of eaters, travel distance, and other factors such as tax.
          </p>
          <Slider
            arrows={true}
            dots={false}
            rows={1}
            variableWidth={false}
            slidesToShow={3}
            slidesToScroll={3}
            infinite={false}
            className={styles.catering_packages_slider}
            responsive={[{
              breakpoint: 1024,
              settings: {
                dots: true,
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },{
              breakpoint: 640,
              settings: {
                dots: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }]}
          >
            {caterer.packages?.sort((a,b) => a.photo ? -1 : 1).map((pkg, index) => (
              <div key={pkg.id}>
                <div className="mr-2 mt-5 shadow bg-white">
                  {pkg.photo ? (
                    <div className="relative" style={{ paddingTop: '40%' }}>
                      <img
                        src={getResizedPhotoUrl(pkg.photo, 500, 500)}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                      />
                    </div>
                  ) : defaultPhoto ? (
                    <div className="relative" style={{ paddingTop: '40%' }}>
                      <img
                        src={getResizedPhotoUrl(defaultPhoto, 500, 500)}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                      />
                    </div>
                  ) : (
                    // if no photo, show a placeholder image
                    <div className="relative bg-gray-400" style={{ paddingTop: '40%' }}>
                      <img
                        src={forkKnife}
                        style={{ width: "50px", height: "50px", top: "50%", left: "50%", transform: "translate3d(-50%,-50%,0)"}}
                        className="absolute"
                      />
                    </div>
                  )}
                  <div className="p-2">
                    <h4 className="text-black text-xl">
                      {pkg.name}
                    </h4>
                    <p className={[styles.ellipsis, "font-light text-md text-gray mb-2"]}>
                      {/* Add a line break if description missing to keep card heights the same */}
                      {pkg.description || <br/>}
                    </p>

                    {/* for each package category, show the category title and then the first three menu items */}
                    {[...new Array(2)].map((element, index) => {
                      const category = pkg.categories[index]
                      return category ? (
                        <div key={index}>
                          <p className={[styles.ellipsis, "text-md"]}>
                            <span className="text-black">{category.name}: </span>
                            <span className="font-thin">
                              {category.items?.slice(0, 3).map((item) => item.name).join(', ')}
                            </span>
                          </p>
                        </div>
                      ) : (
                        // if no category, show an empty line to keep card heights the same
                        <p key={index} className="text-sm">&nbsp;</p>
                      )
                    })}

                    <button 
                      children="View more >"
                      className="text-blue my-2 cursor-pointer text-sm"
                      onClick={() => setCatererPackage(pkg)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : null}
      <Modal
        isOpen={catererPackage}
        closeModal={() => setCatererPackage(null)}
        className="relative m-5 w-full shadow-lg self-start"
        style={{ maxWidth: 600 }}
      >
        <div className="bg-white p-5">
          <div className="flex justify-center">
            <h3 className="flex-1 text-2xl text-navy font-platform">
              {catererPackage?.name}
            </h3>
            <button
              className="btn px-2 py-1 text-red"
              onClick={() => setCatererPackage(null)}
            >
              <X className="w-4 inline" /> Close
            </button>
          </div>
          
          <div className="">
            <p className="text-sm font-light font-italic pb-5">{catererPackage?.description}</p>
            <div className="grid gap-6">
              {catererPackage?.categories?.map(category => (
                <div key={category.id} className="">
                  <div className="pb-1 mb-3 border-gray-200 border-b flex items-center justify-between">
                    <h3 className="text-lg font-bold">
                      {category.name}{' '}
                    </h3>
                  </div>
                  <div className="grid gap-3">
                    {category.items?.map(item => (
                      <div key={item.id} className="">
                        <h4 className="font-normal">
                          {item.name}
                        </h4>
                        <p className="text-sm font-light">
                          {item.description}
                        </p>
                        <div className="font-light text-xs flex flex-wrap">
                          {item.modifiers?.map(modifier => (
                            <Tooltip key={modifier.id} content={modifier.options.map(opt => opt.name).join(', ')}>
                              <div className="pill pill-gray mr-1 mt-1 text-xs cursor-pointer">
                                {modifier.name}
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
