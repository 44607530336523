import React from 'react'
import CatererCarousel from '../modules/CatererCarousel'
import { useBlockContext } from '../BlockContext'
import { getButtonProps } from '../utils'

export default function CatererCarouselBlock({ data }) {
  const ctx = useBlockContext()

  return (
    <CatererCarousel
      heading={data.heading}
      place={ctx.place}
      tags={(data.tags || []).map(tag => ({
        id: tag.id,
        description: tag.text,
        buttonText: tag.button_text,
        buttonUrl: tag.button_url,
      }))}
      {...getButtonProps(data, ctx)}
    />
  )
}
