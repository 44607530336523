import { useFeatureFlagVariantKey } from 'lib/posthog'
import { BlockRenderer } from '../BlockRenderer'

export default function Experiment({ block }) {
  const variant = useFeatureFlagVariantKey(block.data.feature_flag || '')

  return variant && variant === block.data.variant ? (
    <BlockRenderer blocks={block.data.blocks || []} />
  ) : null
}
