import React, { useState, useEffect } from 'react'
import { LocationAutoComplete } from 'app/maps'
import { useClient } from 'jsonapi-react'
import { Input } from 'app/theme/forms'
import { calculate, numberWithCommas, minimumDefaults } from 'app/theme/calculator/helpers'
import styles from './Calculator.module.css'
import { ChevronRight } from 'react-feather'

const foodTypes = [
  { label: 'Entree', value: 'main' },
  { label: 'Drink', value: 'drink' },
  { label: 'Dessert', value: 'dessert' },
]

export default function Calculator({
  heading,
  subheading,
  buttonUrl,
  buttonAction,
  buttonText,
  defaultCity,
  cateringTagPage = false,
  cateringPage = false,
  cuisinePage = false,
  pricePerPerson,
  occasion,
}) {
  const client = useClient()
  const [estimate, setEstimate] = useState(0)
  const [minimum, setMinimum] = useState()

  const [data, setData] = useState({
    city: defaultCity,
    eaters: null,
    foods: [],
  })

  const onFoodChange = (food, checked) => {
    if (checked) {
      setData(prev => ({ ...prev, foods: prev.foods.concat(food) }))
    } else {
      setData(prev => ({ ...prev, foods: prev.foods.filter(f => f !== food) }))
    }
  }

  useEffect(() => {
    if (!data.city || !data.city.location) {
      return
    }
    client.fetch(['catering_minimums', {
      sort: 'distance',
      filter: {
        location: [
          data.city.location.lng,
          data.city.location.lat
        ],
      },
      extra_fields: {
        catering_minimums: ['distance']
      },
      page: {
        size: 1,
      },
    }]).then(res => {
      if (res.data?.length) {
        setMinimum(res.data[0])
      }
    })
  }, [data.city])

  useEffect(() => {
    if (!minimum) {
      return
    }
    if (!data.foods.length) {
      setEstimate(0)
    } else {
      setEstimate(calculate({
        foods: data.foods,
        eaters: data.eaters,
        catering: minimum.catering_min || minimumDefaults.catering,
        dessert: minimum.dessert_min || minimumDefaults.dessert,
        cateringTagPage,
        cateringPage,
        cuisinePage,
        pricePerPerson,
        occasion,
      }))
    }
  }, [minimum, data.foods, data.eaters])

  return (
    <div className="bg-navy text-white py-12 md:py-16 lg:py-32">
      <div className="container">
        <h2 className="text-3xl md:text-5xl leading-tight font-light md:font-normal font-platform text-left lg:text-center">
          {heading}
        </h2>
        <p className="text-2xl text-left lg:text-center font-light py-2 lg:py-3 max-w-3xl mx-auto">
          {subheading}
        </p>
        <div className="max-w-lg mx-auto mt-3 grid gap-6">
          <div>
            <label className="label block mb-2 text-white">
              City
            </label>
            <LocationAutoComplete
              types={['(cities)']}
              onPlaceChanged={city => {
                setData( prev => ({ ...prev, city }) )
              }}
            >
              <input
                type="text"
                className="input-basic text-black text-lg px-4 py-3 rounded-lg"
                value={data.city?.city_name || data.city?.name}
                placeholder="Enter location"
                onChange={event => {
                  setData(prev => ({
                    ...prev,
                    city: { ...prev.city, city_name: event.target.value }
                  }))
                }}
              />
            </LocationAutoComplete>
          </div>
          <Input
            type="number"
            label="How many eaters?"
            labelClassName="text-white"
            className="text-black text-lg px-4 py-3 rounded-lg"
            placeholder="Enter amount"
            value={data.eaters || ''}
            onChange={eaters => {
              setData(prev => ({ ...prev, eaters }))
            }}
          />
          <div>
            <label className="label block mb-3 text-white">
              What are you serving <span className="text-sm ml-2">(Check all that apply)</span>
            </label>
            <div className="flex gap-5">
              {foodTypes.map(food => (
                <label key={food.value} className={styles.checkbox}>
                  <input
                    type="checkbox"
                    checked={data.foods.indexOf(food.value) >= 0}
                    onChange={event => {
                      onFoodChange(food.value, event.target.checked)
                    }}
                  />
                  <span>{food.label}</span>
                </label>
              ))}
            </div>
          </div>
          <div>
            <label className="label block mb-3 text-white">
              Starting Prices
            </label>
            <div className={styles.priceDisplay}>
              ${numberWithCommas(estimate)} USD
            </div>
          </div>
          <div className="flex justify-center mt-2">
            {buttonUrl || buttonAction ? (
              <div>
                {buttonUrl ? (
                  <a
                    href={buttonUrl}
                    className={['btn', styles.button]}
                  >
                    {buttonText}
                    <ChevronRight className="mt-1 ml-1" />
                  </a>
                ) : (
                  <button
                    type="button"
                    className={['btn', styles.button]}
                    onClick={buttonAction}
                    children={buttonText}
                  >
                    {buttonText}
                    <ChevronRight className="mt-1 ml-1" />
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
