import React from 'react'
import { useQuery } from 'jsonapi-react'
import Slider from 'app/theme/slider'
import Stars from 'app/theme/Stars'
import { format } from 'date-fns'
import donutTruck from 'assets/svg/donut-truck.svg'
import mapPin from 'assets/svg/map-icon.svg'
import { Calendar } from 'react-feather'
import styles from './Reviews.module.css'

export default function Reviews({
  heading,
  subheading,
  place,
}) {
  const reviewsQuery = useQuery(place && place.bounding_box && ['reviews', {
    sort: '-created_at',
    filter: {
      rating: [4,5],
      bounding_box: place.bounding_box,
    },
    include: [
      'event',
      'event.address',
      'event.proposal.contact',
      'event.occasion',
      'review_tags'
    ],
    fields: {
      proposals: [],
      events: ['start_date', 'eaters'],
      occasions: ['name'],
      contacts: ['first_name', 'last_name']
    },
    page: {
      size: 9
    },
  }])

  if (reviewsQuery.isLoading) {
    return null
  }

  return (
    <div className="bg-gray-100 pt-10 pb-10 md:pt-16 md:pb-16 lg:pt-32 lg:pb-32">
      <div className="container">
        <h2 className="text-3xl md:text-5xl leading-tight font-light md:font-normal text-black font-platform text-left lg:text-center">
          {heading}
        </h2>
        <p className="text-black text-2xl text-left lg:text-center font-light py-2 lg:py-3 max-w-3xl mx-auto">
          {subheading}
        </p>
      </div>
      <div className="py-10">
        <Slider
          className={styles.slider}
          slidesToShow={3}
          slidesToScroll={1}
          arrows={false}
          dots={false}
          centerMode={true}
          responsive={[
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 430,
              settings: {
                slidesToShow: 1,
                centerMode: true,
              },
            },
          ]}
        >
          {reviewsQuery.data?.map((review, key) => (
            <div key={key} className={styles.slide}>
              <div className="mx-2 sm:mx-5 bg-white p-4 sm:p-6 md:p-10 lg:p-12 flex flex-col h-full rounded-xl text-black shadow-sm">
                <div className="flex items-center mb-5">
                  {/* <img
                    src=""
                    className="h-10 w-10 rounded-full bg-gray-200 mr-4 object-cover"
                  /> */}
                  <div className="flex-1 leading-none">
                    <div className="font-bold text-xl mb-1">
                      {review.name || review.event?.proposal?.contact?.first_name}
                    </div>
                    <div className="font-light sm:hidden">
                      {review.event.start_date ? format(new Date(review.event.start_date), 'MM/dd/yyyy') : null}
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-5">
                  <Stars
                    rating={review.rating}
                    className="mr-1 md:mr-2"
                    size={36}
                  />
                  <span className="font-bold text-2xl -mt-1">
                    {review.rating?.toFixed(1)}
                  </span>
                </div>
                <div className="flex-1 font-light text-xl mb-6">
                  {review.comment}
                </div>
                <div className="flex gap-8 font-light items-center">
                  <div className="flex items-center">
                    <img src={mapPin} className="h-6 mr-2" />
                    {review.event?.address?.city_name}
                  </div>
                  <div className="flex items-center">
                    <img src={donutTruck} className="h-6 mr-2" />
                    {review.event?.occasion?.name}
                  </div>
                  <div className="hidden sm:flex items-center">
                    <Calendar size="20" className="mr-2"  />
                    {review.event.start_date ? format(new Date(review.event.start_date), 'MM/dd/yyyy') : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
