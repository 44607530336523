import React from 'react'
import LazyImg from 'app/theme/LazyImage'
import { ChevronRight } from 'react-feather'
import styles from './HowToBook.module.css'

export default function HowToBook({
  heading,
  imageUrl,
  items = [],
  buttonText,
  buttonAction,
  buttonUrl,
}) {
  return (
    <div className="bg-gray-100 pt-10 pb-12 md:pt-16 md:pb-24 lg:pt-32 lg:pb-40">
      <div className="container">
        <h2 className={styles.heading}>
          {heading}
        </h2>
        <div className="grid lg:grid-cols-2 gap-5 lg:gap-10">
          <div className="flex lg:justify-end">
            <LazyImg
              src={imageUrl}
              className={styles.image}
            />
          </div>
          <ul className="grid gap-4">
            {items.map((item, index) => (
              <li key={index} className="flex flex-col">
                <h3 className="text-black text-2xl font-bold md:text-3xl">
                  {index + 1}. {item.heading}
                </h3>
                <p className="text-lg font-light lg:text-2xl leading-tight">
                  {item.text}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex lg:justify-center mt-8 lg:mt-20">
          {buttonUrl || buttonAction ? (
            <div className="">
              {buttonUrl ? (
                <a
                  href={buttonUrl}
                  className={['btn', styles.button]}
                >
                  {buttonText}
                  <ChevronRight className="mt-1 ml-1" />
                </a>
              ) : (
                <button
                  type="button"
                  className={['btn', styles.button]}
                  onClick={buttonAction}
                  children={buttonText}
                >
                  {buttonText}
                  <ChevronRight className="mt-1 ml-1" />
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
