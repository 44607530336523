import React, { useState } from 'react'
import { useQuery } from 'jsonapi-react'
import TAG_ICONS from 'app/utils/tagIcons'
import { Tag } from 'react-feather'
import Slider from 'app/theme/slider'
import { getResizedPhotoUrl } from 'app/utils/photos'
import truckPic from 'assets/svg/truck.svg'
import { getServiceIcon, getServiceLabels } from 'app/utils/catererServices'
import starBadge from 'assets/svg/star.svg'
import forkKnife from 'assets/svg/fork-knife.svg'
import redHeart from 'assets/svg/red-heart.svg'
import RankingBadge from 'app/theme/RankingBadge'
import { timeInBusiness } from 'app/utils/caterer'
import { ChevronRight, ChevronLeft } from 'react-feather'
import styles from './CatererCarousel.module.css'
import viewMenuButton from 'assets/img/button_viewmenu_rectangle_v3-02-cropped.png'

export default function CatererCarousel({
  heading,
  place,
  tags: tagData = [],
  buttonText,
  buttonAction,
  buttonUrl,
}) {
  const tagIds = tagData.map(t => t.id)
  const [currentTab, setCurrentTab] = useState(0)

  const placeFilter = {}
  if (place && ((place.type === 'city' && place.major) || !place.location)) {
    placeFilter.city_id = place.id
  } else if (place) {
    placeFilter.service_area = {
      contains: JSON.stringify({
        ...place.location,
        radius: 10
      })
    }
  }

  const tagsQuery = useQuery(tagIds.length > 0 && ['tags', {
    filter: {
      id: tagIds,
      ...placeFilter,
    },
    fields: {
      tags: ['name', 'slug']
    },
    extra_fields: {
      tags: ['total_caterers']
    },
  }])

  const tags = tagData.map(tag => ({
    ...tag,
    ...tagsQuery.data?.find(t => t.id === String(tag.id))
  })).filter(tag => {
    return tag.total_caterers > 0
  })

  if (tagsQuery.isLoading) {
    return null
  }

  let currentTag = tags[currentTab] || tags[0]

  let cta = {
    buttonText: currentTag?.buttonText || buttonText,
    buttonUrl: currentTag?.buttonUrl || buttonUrl,
    buttonAction,
  }

  return (
    <div className="bg-navy pt-10 pb-12 md:pt-16 md:pb-24 lg:pt-32 lg:pb-40">
      <div className="container">
        <h2 className={styles.heading}>
          {heading}
        </h2>
      </div>
      <div className="border-b border-navy-400">
        <div className="container">
          <div className="flex lg:justify-center overflow-x-scroll lg:overflow-x-auto no-scrollbar">
            {tags.map((tag, index) => (
              <button
                key={tag.id}
                type="button"
                className={[
                  'btn text-2xl font-bold flex items-center',
                  styles.tabButton,
                  currentTab === index && styles.active,
                ]}
                onClick={() => {
                  setCurrentTab(index)
                }}
              >
                { TAG_ICONS[tag.slug] ? (
                  <img src={TAG_ICONS[tag.slug]} className="h-8 w-8" />
                ) : (
                  <Tag size={16} />
                )}
                <span
                  children={tag.name}
                  className={[
                    'pl-3',
                  ]}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
      <div>
        {tags.map((tag, index) => (
          <div key={tag.id} className={currentTab === index ? '' : 'hidden'}>
            <div className="container py-12 max-w-4xl text-center">
              <p className="text-white text-xl font-light">
                {tag.description}
              </p>
            </div>
            <CatererList
              tag={tag}
              placeFilter={placeFilter}
            />
          </div>
        ))}
      </div>
      <div className="container">
        <div className="flex justify-center mt-16">
          {cta.buttonUrl || cta.buttonAction ? (
            <div className="">
              {cta.buttonUrl ? (
                <a
                  href={cta.buttonUrl}
                  className={['btn', styles.ctaButton]}
                >
                  {cta.buttonText}
                  <ChevronRight className="mt-1 ml-1" />
                </a>
              ) : (
                <button
                  type="button"
                  className={['btn', styles.ctaButton]}
                  onClick={cta.buttonAction}
                >
                  {cta.buttonText}
                  <ChevronRight className="mt-1 ml-1" />
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

function CatererList({
  tag,
  placeFilter,
}) {
  const caterersQuery = useQuery(['caterers', {
    sort: '-ranking',
    filter: {
      ...placeFilter,
      ...(tag.slug === 'dessert' ? { specific_tag_id: tag.id } : { tag_id: tag.id }),
      closed: false,
    },
    include: [
      'photo',
      'tags',
      'story',
      'services',
      'caterer_stat',
    ],
    fields: {
      caterers: ['name', 'slug', 'ranking_tier'],
      photos: ['uuid', 'extension', 'path'],
      caterer_stats: ['total_bookings', 'average_rating'],
      stories: ['established_date'],
    },
    page: {
      size: 9,
    },
  }])

  if (caterersQuery.isLoading) {
    return null
  }

  return (
    <div>
      <Slider
        className={styles.slider}
        arrows={true}
        dots={true}
        centerMode={true}
        infinite={true}
        slidesToShow={6}
        slidesToScroll={1}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        responsive={[
          {
            breakpoint: 2500,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ]}
      >
        {caterersQuery.data?.map((caterer) => (
          <div key={caterer.id} className={styles.slide}>
            <div className="mx-2 bg-white text-black rounded-lg shadow flex flex-col h-full">
              <a
                href={`/${caterer.slug}/`}
                style={{
                  paddingTop: '65%',
                }}
                className={[
                  'block bg-gray-100 relative rounded-t-lg overflow-hidden outline-none',
                ]}
              >
                <img
                  src={caterer.photo ? getResizedPhotoUrl(caterer.photo, 500, 335) : truckPic}
                  className="absolute inset-0 object-cover"
                  alt={`${caterer.name} Catering Menu and Profile`}
                />
              </a>
              <div className="p-5 flex flex-1 flex-col gap-3">
                <div className="flex items-center">
                  <div className="font-semibold text-2xl flex-1 truncate">
                    {caterer.name}
                  </div>
                </div>
                <div className="flex items-center gap-5 font-light text-sm">
                  {caterer.caterer_stat?.average_rating ? (
                    <div className="flex items-center">
                      <img src={starBadge} className="w-5 mr-2" />
                      <span>{Number(caterer.caterer_stat?.average_rating).toFixed(1)}</span>
                    </div>
                  ) : null}
                  {caterer.caterer_stat?.total_bookings ? (
                    <div className="flex items-center">
                      {caterer.ranking_tier ? (
                        <RankingBadge
                          ranking={caterer.ranking_tier}
                          className="w-4 mr-2"
                        />
                      ) : (
                        <img src={forkKnife} className="h-5" />
                      )}
                      {caterer.caterer_stat.total_bookings} Bookings
                    </div>
                  ) : null}
                  {caterer.story?.established_date ? (
                    <div className="flex items-center">
                      <img src={redHeart} className="h-5 mr-2" />
                      {timeInBusiness(caterer.story.established_date)}
                    </div>
                  ) : null}
                </div>
                <div className="pt-1 flex gap-2 flex-wrap font-light text-sm">
                  {caterer.tags.map(tag => {
                    return (
                      <div key={tag.id} className="border border-gray-400 rounded-full px-3 py-1">
                        {tag.name}
                      </div>
                    )
                  })}
                </div>
              </div>
              <a
                href={`/${caterer.slug}/`}
                className="block border-t border-gray-400 p-4 text-center text-xl font-semibold"
              >
                <img
                  src={viewMenuButton}
                  alt={`${caterer.name} Catering Menu`}
                  className="h-4 block mx-auto my-2"
                />
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

function PrevArrow(props) {
  return (
    <button className={props.className} onClick={props.onClick}>
      <ChevronLeft size={34} className="text-black" />
    </button>
  )
}

function NextArrow(props) {
  return (
    <button className={props.className} onClick={props.onClick}>
      <ChevronRight size={34} className="text-black" />
    </button>
  )
}
