import React, { useState } from 'react'
import Header from './LeadHeader'
import Footer from './LeadFooter'
import Input from 'app/theme/forms/Input'

export default function LeadContact(props) {
  const { data, setData, prevStep, submit } = props

  return (
    <div>
      <Header
        title="Let us know how we can be in touch"
      />
      <div className="p-8 grid gap-8 sm:w-3/4 m-auto">
        <Input
          value={data.name}
          placeholder="Your Name"
          classNames={{
            input: 'input-underline',
          }}
          onChange={name => {
            setData(prev => ({ ...prev, name }))
          }}
        />
        <Input
          value={data.phone}
          placeholder="Your Phone Number"
          classNames={{
            input: 'input-underline',
          }}
          onChange={phone => {
            setData(prev => ({ ...prev, phone }))
          }}
        />
      </div>
      <Footer
        prevStep={prevStep}
        submit={submit}
      />
    </div>
  )
}
