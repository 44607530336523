import React from 'react'
import CateringForAnyEvent from '../modules/CateringForAnyEvent'
import { useBlockContext } from '../BlockContext'
import { getButtonProps } from '../utils'

export default function CateringForAnyEventBlock({ data }) {
  const ctx = useBlockContext()

  return (
    <CateringForAnyEvent
      heading={data.heading}
      subheading={data.subheading}
      items={data.items?.map(item => ({
        logo: item.logo,
        heading: item.heading,
        subheading: item.subheading,
        linkText: item.link_text,
        linkUrl: item.link_url,
      })) || []}
      secondaryHeading={data.secondary_heading}
      secondarySubheading={data.secondary_subheading}
      {...getButtonProps(data, ctx)}
    />
  )
}
