import React from 'react'
import { ChevronRight } from 'react-feather'
import styles from './CateringForAnyEvent.module.css'
import Slider from 'app/theme/slider'

import computer from 'assets/svg/computer.svg'
import partyHat from 'assets/svg/party-hat.svg'
import graduationCap from 'assets/svg/graduation-cap.svg'
import filmCamera from 'assets/svg/film.svg'
import weddingCake from 'assets/svg/wedding-cake.svg'
import balloons from 'assets/svg/balloons.svg'
import mic from 'assets/svg/mic.svg'
import briefcase from 'assets/svg/briefcase.svg'

const iconMap = {
  'computer': {
    url: computer,
    alt: 'An illustration of a computer',
  },
  'cap': {
    url: graduationCap,
    alt: 'An illustration of a graduation cap',
  },
  'balloons': {
    url: balloons,
    alt: 'An illustration of balloons',
  },
  'camera': {
    url: filmCamera,
    alt: 'An illustration of a film camera',
  },
  'partyhat': {
    url: partyHat,
    alt: 'An illustration of a party hat',
  },
  'microphone': {
    url: mic,
    alt: 'An illustration of a microphone',
  },
  'briefcase': {
    url: briefcase,
    alt: 'An illustration of a briefcase',
  },
  'weddingcake': {
    url: weddingCake,
    alt: 'An illustration of a wedding cake',
  },
}

export default function CateringForAnyEvent({
  heading,
  subheading,
  items = [],
  secondaryHeading,
  secondarySubheading,
  buttonText,
  buttonAction,
  buttonUrl,
}) {
  return (
    <div className="bg-white pt-10 pb-12 md:pt-16 md:pb-24 lg:pt-32 lg:pb-40 flex flex-col gap-6 lg:gap-12">

      <div className="container">
        <h2 className="text-3xl md:text-5xl font-light md:font-normal text-black font-platform text-left lg:text-center">
          {heading}
        </h2>
        <p className="text-black text-2xl text-left lg:text-center font-light py-2 lg:py-3 max-w-3xl mx-auto">
          {subheading}
        </p>
      </div>

      <div className="container hidden md:block">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10">
          {items.map((item, key) => (
            <EventItem key={key} item={item} />
          ))}
        </div>
      </div>

      <div className="md:hidden">
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          dots={false}
          centerMode={true}
        >
          {items.map((item, key) => (
            <div key={key} className="container max-w-none">
              <EventItem item={item} />
            </div>
          ))}
        </Slider>
      </div>

      <div className="container md:pt-6">
        {secondaryHeading && (
          <h3 className="text-3xl md:text-5xl font-light md:font-normal text-black font-platform text-left lg:text-center">
            {secondaryHeading}
          </h3>
        )}
        {secondarySubheading && (
          <p className="text-black text-2xl text-left lg:text-center font-light py-2 max-w-3xl mx-auto">
            {secondarySubheading}
          </p>
        )}
        <div className="flex lg:justify-center mt-6">
          {buttonUrl || buttonAction ? (
            <div className="">
              {buttonUrl ? (
                <a
                  href={buttonUrl}
                  className={['btn', styles.button]}
                >
                  {buttonText}
                  <ChevronRight className="mt-1 ml-1" />
                </a>
              ) : (
                <button
                  type="button"
                  className={['btn', styles.button]}
                  onClick={buttonAction}
                  children={buttonText}
                >
                  {buttonText}
                  <ChevronRight className="mt-1 ml-1" />
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>

    </div>
  )
}

function EventItem({ item }) {
  return (
    <div className="bg-gray-200 text-center p-10 flex flex-col rounded-xl items-center">
      <div className="flex-1">
        {iconMap[item.logo] && (
          <img
            src={iconMap[item.logo].url}
            alt="An illustration of a computer"
            className="mx-auto mb-1 h-20 w-auto"
          />
        )}
        <a href="/food-truck-catering/corporate-catering/" className="text-xl text-black font-bold block mb-4">
          {item.heading}
        </a>
        <p className="text-md font-light block">
          {item.subheading}
        </p>
      </div>
      {item.linkText && item.linkUrl ? (
        <a
          href={item.linkUrl}
          className="text-blue items-center text-xl mt-4 mx-auto inline-block"
        >
          <span className="flex">
            {item.linkText}
            <ChevronRight className="mt-1" />
          </span>
        </a>
      ) : null}
    </div>
  )
}
