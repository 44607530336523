import React from 'react'

export default function GoodFors(props) {
  const { goodFors = [] } = props

  if (!goodFors.length) {
    return null
  }

  return (
    <div className="container py-6 text-navy leading-none lg:flex items-center">
      <p className="text-3xl mb-4 lg:mb-0 lg:text-left lg:mr-6">
        Super Great For
      </p>
      <ul className="uppercase grid grid-cols-2 gap-3 text-center sm:flex lg:text-lg">
        {goodFors.slice(0,4).map(goodFor => {
          return (
            <li
              key={goodFor.id}
              className="bg-white shadow px-4 py-2 sm:mr-2 lg:ml-2 tracking-wide"
              children={goodFor.name}
            />
          )
        })}
      </ul>
    </div>
  )
}
