import React from 'react';
import quotes from 'assets/svg/quotes-navy.svg'

export default function Quote(props) {
  const { caterer, quote, author } = props

  return (
    <div className="bg-white">
      <div className="container max-w-2xl py-16 text-center text-navy">
        <img src={quotes} className="w-12 md:w-16 mx-auto mb-5" />
        <p className="font-platform text-3xl lg:text-4xl leading-snug tracking-wide">
          {quote}
        </p>
        {author && (
          <div className="uppercase mt-8">
            {author} <span className="lowercase italic">of</span> {caterer.name}
          </div>
        )}
      </div>
    </div>
  )
}
