import React from 'react'

export default function LeadFail({ closeModal }) {
  return (
    <div className="bg-blue">
      <div className="text-center p-12">
        <h2 className="font-platform text-4xl text-white text-center">
          Oh no!
        </h2>
        <p className="font-light text-md text-white text-center">
          There was an error processing your request, please try again.
        </p>
        <button onClick={closeModal} className="mt-8 bg-white text-blue text-xl px-10 py-1 font-platform font-light">
          Close
        </button>
      </div>
    </div>
  )
}
