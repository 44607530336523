import React from 'react'
import WhyChooseUs from '../modules/WhyChooseUs'
import { useBlockContext } from '../BlockContext'
import { getButtonProps } from '../utils'

export default function WhyChooseUsBlock({ data }) {
  const ctx = useBlockContext()

  return (
    <WhyChooseUs
      heading={data.heading}
      items={(data.items || []).map(item => ({
        heading: item.heading,
        description: item.description,
        imageUrl: item.image_url,
        imageTitle: item.image_title,
        ...getButtonProps(item, ctx)
      }))}
    />
  )
}
