import React from 'react';
import styles from './Menu.module.css'
import Tooltip from 'app/theme/Tooltip'
import { getResizedPhotoUrl } from 'app/utils/photos'

export default function Menu(props) {
  const { catererName, categories, itemPhotos, defaultPhoto, hasQuote, className } = props

  const pullTop = hasQuote && (itemPhotos.length > 2 || defaultPhoto)
  const pushTop = hasQuote && !pullTop

  return (
    <div className={className}>
      {pullTop && (
        <div className="bg-white sm:h-20 lg:h-24 xl:h-32" />
      )}
      <div
        className={[
          'container sm:mb-12 md:mb-16',
          pullTop && 'sm:-mt-20 lg:-mt-24 xl:-mt-32',
          pushTop && 'mt-6',
        ]}
      >
        <div className="flex -mx-5 sm:mx-0">
          {itemPhotos.length > 2 ? (
            itemPhotos.map((photo, index) => (
              <div
                key={photo.id}
                className={styles.itemPhoto}
              >
                <div
                  className={styles.spacer}
                />
                <img
                  src={getResizedPhotoUrl(photo, 500, 500)}
                />
              </div>
            ))
          ) : defaultPhoto ? (
            <div
              className="flex-grow bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${getResizedPhotoUrl(defaultPhoto, 1200, null)})`,
                paddingTop: '29.8%'
              }}
            />
          ) : null}
        </div>
        <div className="sm:bg-white pt-6 pb-10 px-2 md:px-10 sm:px-6 md:pb-12 lg:pt-12 lg:pb-16">
          <h2 className="font-platform text-navy text-center text-4xl md:text-5xl">
            {catererName} Menu
          </h2>
          <div className="grid gap-4 lg:gap-6">
            {categories.map(cat => (
              <div key={cat.id} className="mt-4" >
                <p className="font-platform text-navy text-3xl md:text-4xl border-b border-gray-300">
                  {cat.name}
                </p>
                <div className="grid gap-4 mt-4 lg:grid-cols-2 lg:gap-6 lg:mt-6">
                  {cat.items.map(item => (
                    <div
                      key={item.id}
                      className={[
                          styles.menuItem,
                      ]}>
                      <p className="font-platform text-navy text-xl md:text-lg">
                        {item.name}
                      </p>
                      <p>
                        {item.description}
                      </p>
                      {item.options?.length > 0 && (
                        <div className="mt-2 flex flex-wrap leading-none -ml-1 -mb-1">
                          {item.modifiers?.map((modifier, modifierIndex) => (
                            <Tooltip key={modifierIndex} content={modifier.options.map(opt => opt.name).join(', ')}>
                              <div
                                className={[
                                  'py-1 px-3 mr-1 mb-1 font-normal float-left text-gray-900 text-xs',
                                  'bg-white border-2 border-gray-400 rounded-full'
                                ]}
                              >
                                {modifier.name}
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
