import React, { useState } from 'react'
import Gallery from './Gallery'
import CertifiedCheckmark from 'app/theme/CertifiedCheckmark'
import truckPic from 'assets/svg/truck.svg'
import styles from './Hero.module.css'
import { getResizedPhotoUrl } from 'app/utils/photos'

export default function Hero({ caterer, photos, preferred, showLeadForm }) {
  const mainPhoto = photos[0]
  const [showPhotos, setShowPhotos] = useState(false)

  return (
    <div
      className={[
        styles.container,
        'bg-navy text-white flex overflow-hidden',
      ]}
    >
      {preferred && !caterer.closed ? (
        <div className="w-full md:flex">

          {mainPhoto && (
            <div className="md:w-2/5 md:flex relative">
              {photos.length > 1 && (
                <div className="w-full absolute left-0" style={{bottom: '5%'}}>
                  <button
                    className='px-4 py-1 btn btn-blue block m-auto'
                    children='More Photos'
                    onClick={() => { setShowPhotos(true) }}
                  />
                </div>
              )}
              <div
                style={{
                  backgroundImage: `url(${getResizedPhotoUrl(mainPhoto, 1200, null)})`
                }}
                className={[
                  styles.mainPhoto,
                  'bg-center bg-cover bg-no-repeat w-full',
                  photos.length > 1 && 'cursor-pointer'
                ]}
                onClick={() => {
                  if (photos.length > 1) {
                    setShowPhotos(true)
                  }
                }}
              />
            </div>
          )}
          <div className="py-16 px-5 text-center md:flex-1 md:flex flex-col justify-center">
            <h1 className="font-platform leading-none text-5xl xl:text-6xl">
              {caterer.name}
              {caterer.verified && (
                <CertifiedCheckmark
                  className="inline-block w-6 ml-2 -mt-3 md:w-8" alt="Certified"
                />
              )}
            </h1>
            <div className="">
              <button
                type="button"
                children="Book Catering"
                className="btn btn-blue font-platform shadow px-6 py-2 text-xl mt-6 mx-3 lg:text-2xl lg:px-8"
                onClick={() => showLeadForm()}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-1 relative">
          {mainPhoto ? (
            <img src={getResizedPhotoUrl(mainPhoto, 1200, null)} className="m-auto max-h-full" />
          ) : (
            <img src={truckPic} className="mx-auto max-h-full py-20" />
          )}
          {caterer.closed && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="relative px-12 py-4 font-bold transform" style={{ '--transform-rotate': '-25deg' }}>
                <div className="absolute inset-0 bg-blue shadow" />
                <div className="relative text-6xl">Closed!</div>
              </div>
            </div>
          )}
        </div>
      )}
      {showPhotos && (
        <Gallery
          photos={photos}
          close={() => {
            setShowPhotos(false)
          }}
        />
      )}
    </div>
  )
}
